import React, { useEffect } from 'react'
import Container from '@material-ui/core/Container'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Picture from '../../components/Picture'

import VideoModal from '../../components/ModalVideo'
import BoxFeature from '../../components/FeaturesLPs/box'
import Feature from '../../components/FeaturesLPs/feature'
import TitleFeature from '../../components/FeaturesLPs/title'
import ImageFeature from '../../components/FeaturesLPs/image'
import VideoWithModal from '../../components/VideoWithModal'
import FormNewsletter from '../../components/Newsletter'
import Accordion from '../../components/Accordion'

import { ditoTrack } from '../../helpers/dito'

import {
  bannerTopoLg,
  bannerTopoMd,
  bannerTopoXs,
  blastFF,
  blastFFLogo,
  featureCabedal1,
  featureCabedal2,
  featureSola1,
  featureSola2,
  featureSola3,
  icoDrop,
  icoEspecificacoesAltura,
  icoEspecificacoesPeso,
  icoPeso,
  nimbus23,
  nimbus23Azul,
  nimbus23RosaAzul,
  nimbus24,
  nimbus24Cabedal,
  nimbus24Sola,
  runningGuys,
  runningGuysXs,
  thumbVideo,
} from '../../images/gelnimbus24'

import * as S from './styles'
import { graphql } from 'gatsby'

const accordionDataNimbus23 = [
  {
    title: 'Amortecimento em FLYTEFOAM™ e GEL™',
    content:
      'Mais leve que espumas comuns, FF™ oferece ainda mais amortecimento e absorção de choque.',
  },
  {
    title: 'Solado em borracha AHARPLUS™',
    content:
      'Este material é estrategicamente colocado para melhorar a durabilidade e ajudar os corredores a obter mais segurança.',
  },
]

const accordionDataNimbus24 = [
  {
    title: 'Novo amortecimento em FF BLAST™ PLUS',
    content:
      'Esta espuma de entressola é mais leve e macia do que o amortecimento FF BLAST ™ , mas ainda oferece a mesma sensação energizada para o impulso.',
  },
  {
    title: 'Solado em borracha AHARPLUS™',
    content:
      'Este material é estrategicamente colocado para melhorar a durabilidade e ajudar os corredores a obter mais segurança com mais resistência e melhor tração.',
  },
  {
    title: 'Novo solado em ASICS LITE™',
    content:
      'Este material é mais leve e resistente do que os materiais de sola padrão. foi projetado para melhorar a resistência contra abrasões.',
  },
  {
    title: 'Novo lingueta em KNIT',
    content:
      'A construção da lingueta cria uma sensação mais suave e macia de conforto fornecendo melhor suporte e ajuste no calce.',
  },
]

const GelNimbus24Page = () => {
  useEffect(() => {
    ditoTrack('acessou-nimbus-24')
  }, [])

  return (
    <Layout>
      <SEO title="GEL NIMBUS-24™" />

      <VideoModal videoId="KF-CeQD_RtQ" />

      <Picture
        xs={bannerTopoXs}
        md={bannerTopoMd}
        lg={bannerTopoLg}
        alt="GEL NIMBUS-24™ - Ícone como sempre, leve como nunca."
      />

      <S.About>
        <Picture
          xs={runningGuysXs}
          md={runningGuysXs}
          lg={runningGuysXs}
          alt="Mais leve, mais confortável para distâncias ainda maiores"
          className="about-image visible-xs"
        />

        <S.AboutText>
          <h1>
            Mais leveza, responsividade <br /> e amortecimento para uma <br />{' '}
            corrida nas alturas
            <br />
            <strong>GEL-NIMBUS ™ 24</strong>
          </h1>
        </S.AboutText>

        <Picture
          xs={runningGuys}
          md={runningGuys}
          lg={runningGuys}
          alt="Mais leve, mais confortável para distâncias ainda maiores"
          className="about-image hidden-xs"
        />
      </S.About>

      {/* TODO: Atualizar thumb quando tiver */}
      <VideoWithModal
        classname="gel-nimbus24"
        title="O clássico que você já conhece,"
        subtitle=" ainda melhor!"
        thumb={thumbVideo}
      />

      <S.RecursosTecno>
        <Container>
          <h2>Tecnologias ainda mais aprimoradas</h2>
          <h3>para corridas de longas distâncias com alto desempenho.</h3>

          <S.RecursosTecnoThumb>
            <Picture
              xs={nimbus23RosaAzul}
              md={nimbus23RosaAzul}
              lg={nimbus23RosaAzul}
              alt="GEL NIMBUS-24™ Rosa e Azul"
            />
            <Picture
              xs={nimbus23Azul}
              md={nimbus23Azul}
              lg={nimbus23Azul}
              alt="GEL NIMBUS-24™ Azul"
            />
          </S.RecursosTecnoThumb>
        </Container>
      </S.RecursosTecno>

      <S.FeaturesWrapper>
        <BoxFeature>
          <div className="item">
            <ImageFeature
              src={nimbus24Cabedal}
              alt="Cabedal"
              classname="thumb-cabedal-nimbus24"
            />
          </div>
          <div className="item">
            <TitleFeature>
              Cabedal com
              <br />
              <strong>ajuste aprimorado</strong>
            </TitleFeature>

            <S.FeaturesList>
              <Feature>
                <img src={featureCabedal1} alt="feature Cabedal" />
                <p>
                  <strong>JACQUARD MESH</strong>
                  <br />
                  Cabedal macio e respirável com melhor ajuste aos pés.
                </p>
              </Feature>
              <Feature>
                <img src={featureCabedal2} alt="feature Cabedal" />
                <p>
                  <strong>Lingueta em Knit</strong>
                  <br />
                  Construção mais confortável que proporciona melhor suporte,
                  ajuste e maciez durante o calce.
                </p>
              </Feature>
            </S.FeaturesList>
          </div>
        </BoxFeature>

        <div className="boxBlasFF">
          <div className="boxBlasFF__texto">
            <img src={blastFFLogo} alt="Blast+ logo" className="logo" />
            <p>
              Espuma responsiva localizada na entressola ainda mais leve e macia
              para amortecimento energizado.
            </p>
          </div>
          <img src={blastFF} alt="BlasFF" className="modelo" />
        </div>

        <BoxFeature reverse>
          <div className="item hidden-xs">
            <ImageFeature
              src={nimbus24Sola}
              alt="Sola"
              classname="thumb-sola"
              reverse
            />
          </div>
          <div className="item">
            <TitleFeature classname="title-nimbus24">
              <strong>sola e entressola</strong>
              <br />
              mais tecnologia, mais amortecimento e mais responsividade
            </TitleFeature>

            <S.FeaturesList>
              <Feature>
                <img src={featureSola1} alt="feature Sola" />
                <p>
                  <strong>GEL ™</strong>
                  <br />
                  Tecnologia para máximo conforto e absorção de impacto.
                </p>
              </Feature>
              <Feature>
                <img src={featureSola2} alt="feature Sola" />
                <p>
                  <strong>TRUSSTIC™</strong>
                  <br />
                  Placa de estabilidade localizada na parte interna do calçado
                  oferece mais eficiência das passadas.
                </p>
              </Feature>
              <Feature>
                <img src={featureSola3} alt="feature Sola" />
                <p>
                  <strong>ASICS LITE™</strong>
                  <br />
                  Borracha muito leve e resistente com alta durabilidade.
                </p>
              </Feature>
            </S.FeaturesList>
          </div>
        </BoxFeature>
      </S.FeaturesWrapper>

      <S.ModelosWrapper>
        <S.ModelosTituloWrapper>
          <strong>Descubra o que mudou</strong>
        </S.ModelosTituloWrapper>

        <S.ModelosContentTecnologia>
          <S.ModelosItemTecnologia>
            <img src={nimbus23} alt="gel-nimbus ™ 23" className="thumb" />
            <h2>gel-nimbus ™ 23</h2>
            <S.ModelosAccordionTecnologia>
              <div className="accordion">
                {accordionDataNimbus23.map(({ title, content }) => (
                  <Accordion key={title} title={title} content={content} />
                ))}
              </div>

              <div className="especificacoes">
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesPeso} alt="" />
                  <p>
                    Peso modelo masculino = <span>310g</span>
                  </p>
                </div>
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesAltura} alt="" />
                  <p>
                    Altura entressola = <span>25mm/15mm</span>
                  </p>
                </div>
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesPeso} alt="" />
                  <p>
                    Peso modelo feminino = <span>260g</span>
                  </p>
                </div>
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesAltura} alt="" />
                  <p>
                    Altura entressola = <span>27mm/14mm</span>
                  </p>
                </div>
              </div>
            </S.ModelosAccordionTecnologia>
          </S.ModelosItemTecnologia>

          <S.ModelosItemTecnologia>
            <img src={nimbus24} alt="gel-nimbus ™ 24" className="thumb" />
            <h2>gel-nimbus ™ 24</h2>
            <S.ModelosAccordionTecnologia>
              <div className="accordion">
                {accordionDataNimbus24.map(({ title, content }) => (
                  <Accordion key={title} title={title} content={content} />
                ))}
              </div>

              <div className="especificacoes">
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesPeso} alt="" />
                  <p>
                    Peso modelo masculino = <span>290g</span>
                  </p>
                </div>
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesAltura} alt="" />
                  <p>
                    Altura entressola = <span>26mm/16mm</span>
                  </p>
                </div>
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesPeso} alt="" />
                  <p>
                    Peso modelo feminino = <span>247g</span>
                  </p>
                </div>
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesAltura} alt="" />
                  <p>
                    Altura entressola = <span>28mm/15mm</span>
                  </p>
                </div>
              </div>
            </S.ModelosAccordionTecnologia>
          </S.ModelosItemTecnologia>
        </S.ModelosContentTecnologia>
      </S.ModelosWrapper>

      <S.OndeComprarWrapper>
        <div className="container">
          <h2>Especificações técnicas:</h2>
          <div className="informacoes">
            <div className="informacoes__bloco">
              <img src={icoDrop} alt="" />
              <p>
                Drop = 10mm (16/26mm) (M)
                <br />
                Drop = 13mm (15/28mm) (F)
              </p>
            </div>
            <div className="informacoes__bloco">
              <img src={icoPeso} alt="" />
              <p>
                Peso = 290g (M)
                <br />
                Peso = 247g (F)
              </p>
            </div>
          </div>
          <span>R$ 1099,99</span>

          <S.ButtonComprarWrapper>
            {/* Atualizar URL do produto */}
            <S.ButtonComprar
              href="https://www.asics.com.br/calcados?filtro_87=GEL-Nimbus-24&utm_source=lab&utm_medium=lp&utm_campaign=crm-lp-nimbus-24&paged=1"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              comprar agora
            </S.ButtonComprar>
            <S.ButtonComprar
              href="https://api.whatsapp.com/send?phone=551150568450&text=Quero%20garantir%20meu%20Nimbus24"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              comprar pelo whatsapp
            </S.ButtonComprar>
          </S.ButtonComprarWrapper>
        </div>
      </S.OndeComprarWrapper>

      <S.WrapperNewsletter>
        <S.ContentNewsletter>
          <h2>Cadastre seu e-mail para receber ofertas e novidades</h2>

          <S.FormNewsletter>
            <FormNewsletter isLP origem="lp-lab-nimbus24" />
          </S.FormNewsletter>
        </S.ContentNewsletter>
      </S.WrapperNewsletter>
    </Layout>
  )
}

export default GelNimbus24Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
